import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const TagManagerProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TXFB7C5' })
  }, [])

  return <div>{children}</div>
}

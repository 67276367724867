import type { ReactNode } from 'react'
import React, { createContext, useContext, useMemo } from 'react'
import { breakpoints } from 'utils/breakpoints'
import { useWindowSize } from '../../hooks/useWindowSize'

export type MediaQueryContextType = {
  mobile: boolean
  tablet: boolean
  laptop: boolean
  desktop: boolean
}

export interface Props {
  children: ReactNode
}

const initialValue: MediaQueryContextType = {
  mobile: true,
  tablet: false,
  laptop: false,
  desktop: false,
}

const MediaQueryContext = createContext<MediaQueryContextType>(initialValue)

export const MediaQueryProvider = ({ children }: Props) => {
  const { width } = useWindowSize()

  const value = useMemo(
    () => ({
      mobile: width >= breakpoints.mobile,
      tablet: width >= breakpoints.tablet,
      laptop: width >= breakpoints.laptop,
      desktop: width >= breakpoints.desktop,
    }),
    [width],
  )

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>
}

export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext)
}

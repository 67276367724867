import { createGetKcContext } from 'keycloakify/login'
import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'
import type { CommonKcContext, KcContextExtension } from './kcContext.types'

const commonContextValues = {
  env: 'local',
  registrationType: 'DEFAULT' as CommonKcContext['registrationType'],
  url: {
    registrationAction:
      '/auth/realms/Fourthwall/login-actions/registration?session_code=GFmmguhhsojFywAyMsrC4a72cDhDA1BNqwejfiWRitI&execution=935d5540-a513-4c3e-a027-f91779df2335&client_id=Dashboard&tab_id=0tfD5wQXIgo',
  },
  fwUrl: {
    getStartedUrl:
      '/auth/realms/Fourthwall/protocol/openid-connect/registrations?client_id=Dashboard&response_type=code&redirect_uri=https%3A%2F%2Fmy-shop.staging.fourthwall.com%2Fadmin%2Fdashboard?redirect%3D%26favoriteProduct%3Dbella-canvas-supersoft-t-shirt-migrated-dtg',
    loginUrl:
      '/auth/realms/Fourthwall/protocol/openid-connect/auth?client_id=Dashboard&response_type=code&redirect_uri=https%3A%2F%2Fmy-shop.staging.fourthwall.com%2Fadmin%2Fdashboard?redirect%3D%26favoriteProduct%3Dbella-canvas-supersoft-t-shirt-migrated-dtg',
  },
  customImgSrc:
    'https://cdn.staging.fourthwall.com/customization/customization/cud_tFiNeUGRTRWM7Lsuw4dQzw/101bd483-1005-498c-a34c-22025b3b6678.jpeg',
  client: {
    clientId: 'Dashboard',
  } as CommonKcContext['client'],
  social: {
    providers: [
      {
        displayName: 'Google',
        loginUrl:
          '/auth/realms/Fourthwall/broker/google/login?client_id=Dashboard&tab_id=RjNiQNr1oJo&session_code=mviNW-MFHZ2mDSGpNE5xroh4mGx6YRN5D-uZK0A165Y',
        providerId: 'google',
      },
      {
        displayName: 'Twitter V2',
        loginUrl:
          '/auth/realms/Fourthwall/broker/twitter-v2/login?client_id=Dashboard&tab_id=RjNiQNr1oJo&session_code=mviNW-MFHZ2mDSGpNE5xroh4mGx6YRN5D-uZK0A165Y',
        providerId: 'twitter-v2',
      },
      {
        displayName: 'Twitch',
        loginUrl:
          '/auth/realms/Fourthwall/broker/twitch/login?client_id=Dashboard&tab_id=RjNiQNr1oJo&session_code=mviNW-MFHZ2mDSGpNE5xroh4mGx6YRN5D-uZK0A165Y',
        providerId: 'twitch',
      },
      {
        displayName: 'Youtube',
        loginUrl:
          '/auth/realms/Fourthwall/broker/youtube/login?client_id=Dashboard&tab_id=RjNiQNr1oJo&session_code=mviNW-MFHZ2mDSGpNE5xroh4mGx6YRN5D-uZK0A165Y',
        providerId: 'youtube',
      },
      {
        displayName: 'Apple',
        loginUrl:
          '/auth/realms/Fourthwall/broker/apple/login?client_id=Dashboard&tab_id=RjNiQNr1oJo&session_code=mviNW-MFHZ2mDSGpNE5xroh4mGx6YRN5D-uZK0A165Y',
        providerId: 'apple',
      },
    ],
  },
}

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'preonboarding.ftl',
      preonboarding: {},
      ...commonContextValues,
    },
    {
      pageId: 'register-v3.ftl',
      ...commonContextValues,
      fwUrl: {
        ...commonContextValues.fwUrl,
        requestAnInvitationUrl: 'https://fourthwall.com/request-an-invite/',
      },
    },
    {
      pageId: 'missing-scopes-oauth.ftl',
      ...commonContextValues,
    },
    {
      pageId: 'request-invite.ftl',
      ...commonContextValues,
    },
    {
      pageId: 'login-config-totp.ftl',
      ...commonContextValues,
      // mode: 'manual',
      // isAppInitiatedAction: true,
    },
    {
      pageId: 'select-authenticator.ftl',
    },
    {
      pageId: 'webauthn-authenticate.ftl',
      shouldDisplayAuthenticators: true,
      authenticators: {
        authenticators: [
          {
            credentialId: 'credentialId',
            transports: {
              iconClass: 'iconClass',
              displayNameProperties: ['access-denied', 'accountUnusable'],
            },
            label: 'label',
            createdAt: 'createdAt',
          },
          {
            credentialId: 'credentialId',
            transports: {
              iconClass: 'iconClass',
              displayNameProperties: ['access-denied', 'accountUnusable'],
            },
            label: 'label',
            createdAt: 'createdAt',
          },
        ],
      },
      auth: {
        showTryAnotherWayLink: true,
      },
    },
    {
      pageId: 'login-otp.ftl',
      auth: {
        showTryAnotherWayLink: true,
      },
    },
  ],
})

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  mockPageId: 'register-v3.ftl',
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>

type KeycloakContextType = {
  kcContext: KcContext
}

export const KeycloakContext = createContext<KeycloakContextType>({
  kcContext,
})

export interface KeycloakContextProviderProps {
  kcContext: KcContext
  children: ReactNode
}

export const KeycloakContextProvider = ({ kcContext, children }: KeycloakContextProviderProps) => {
  const value = useMemo(() => ({ kcContext }), [kcContext])

  return <KeycloakContext.Provider value={value}>{children}</KeycloakContext.Provider>
}

export const useKeycloakContext = <T extends unknown>() => {
  const context = useContext(KeycloakContext)

  return { kcContext: context.kcContext as T }
}

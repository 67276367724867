
export type Breakpoints = {
  mobile: number
  tablet: number
  laptop: number
  desktop: number
}

export const breakpoints: Breakpoints = {
  mobile: 375,
  tablet: 768,
  laptop: 1024,
  desktop: 1920,
}

export const minWidthCondition = (key: keyof Breakpoints) => `(min-width: ${breakpoints[key]}px)`

export const mediaQueries = (key: keyof Breakpoints) => `@media ${minWidthCondition(key)}`